import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import Root from './Root';

import './i18n';
import LoadingScreen from './Components/Common/LoadingScreen';

const App = React.lazy(() => import('./App')); // Lazy load the App component

ReactDOM.render(
    <React.StrictMode>
        <Root>
            <Suspense fallback={<LoadingScreen />}>
                <App />
            </Suspense>
        </Root>
    </React.StrictMode>,
    document.getElementById('root'),
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
