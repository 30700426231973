import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

export default function Root({ children }: { children: React.ReactElement }): React.ReactElement {
    return (
        <RecoilRoot>
            <Router>{children}</Router>
        </RecoilRoot>
    );
}
