import React from 'react';
import styled, { keyframes } from 'styled-components';
import animationData from '../../../public/SA_icones_loading.json';
import Lottie from 'lottie-react';

// Animation CSS pour le rebond
const bounce = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
`;

// Style du wrapper plein écran (sans couvrir le header et le footer)
const FullScreenWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; // Utiliser 100vh pour couvrir la hauteur de la vue

    z-index: 9999;
`;

// Style pour le composant Lottie
const StyledLottie = styled(Lottie)`
    width: 250px; // Réduire la taille
    height: 250px; // Réduire la taille
    opacity: 0.8; // Changer l'opacité
`;

const LoadingScreen: React.FC = () => {
    return (
        <FullScreenWrapper>
            <StyledLottie animationData={animationData} autoPlay loop />
        </FullScreenWrapper>
    );
};

export default LoadingScreen;
